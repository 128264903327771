<template>
  <div class="contrainer minWidth1000">
    <div class="contrainer-right">
      <custom-actions>
        <el-form inline label-width="100px">
          <el-form-item label="用户名称">
            <el-input v-model="searchForm.userName" placeholder="用户名称关键字" />
          </el-form-item>
          <el-form-item label="模块名称">
            <el-input v-model="searchForm.module" placeholder="模块名称关键字" />
          </el-form-item>
          <el-form-item label="功能名称">
            <el-input v-model="searchForm.content" placeholder="功能名称关键字" />
          </el-form-item>
           <el-form-item label="操作日期">
          <el-date-picker
            v-model="dateRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :clearable="false"
            @change='changeTime'
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          </el-form-item>
        </el-form>
      </custom-actions>

      <el-card shadow="never" class="my-table" :body-style="{ padding: '15px' }">
        <el-table stripe size="mini" border v-loading="loading" :data="tableData">
          <el-table-column min-width="100" label="用户名称" prop="userName"></el-table-column>
          <el-table-column min-width="100" label="模块名称" prop="module"></el-table-column>
          <el-table-column min-width="150" label="功能名称" prop="content"></el-table-column>
          <el-table-column min-width="150" label="请求路径" prop="url"></el-table-column>
          <el-table-column min-width="60" label="操作结果" prop="result"></el-table-column>
          <el-table-column min-width="90" label="IP" prop="remoteIp"></el-table-column>
          <el-table-column min-width="90" label="浏览器" prop="remoteBrowser"></el-table-column>
          <el-table-column min-width="60" label="操作系统" prop="remoteOs"></el-table-column>
          <el-table-column min-width="150" label="操作时间" prop="createTime"></el-table-column>
        </el-table>

        <el-pagination
          class="mt10"
          :current-page.sync="page"
          background
          layout="total, prev, pager, next"
          :total="total"
          @current-change="loadData"
        >
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlarmList',
  data () {
    return {
      tableData: [],
      page: 1,
      total: 0,
      loading: false,
      dateRange: [new Date(new Date().toDateString()), new Date()],
      searchForm: {
        userName: null,
        module: null,
        content: null,
        startTime: null,
        endTime: null
      }
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
    this.initStartEndTime()
    this.loadData()
  },
  methods: {
    initStartEndTime () {
      const dayjs = this.$dayjs
      this.searchForm.startTime = dayjs().format('YYYY-MM-DD')
      this.searchForm.endTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
    },
    changeTime (arr) {
      if (arr && arr.length > 0) {
        this.searchForm.startTime = arr[0]
        this.searchForm.endTime = arr[1]
      } else {
        this.searchForm.startTime = null
        this.searchForm.endTime = null
      }
    },
    loadData () {
      this.loading = true
      this.$axios
        .get(this.$apis.system.selectOperatorLogByPage, {
          params: {
            page: this.page,
            ...this.searchForm
          }
        })
        .then(res => {
          this.tableData = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleNodeClick (data) {
      console.log(data)
      this.searchForm.stationId = data.deptId
      this.loadData()
    },
    onSearch () {
      this.page = 1
      this.loadData()
    }
  }
}
</script>

<style scoped lang="scss">
.tabs {
  margin-top: -10px;
}

.org-item {
  display: flex;
  align-items: center;

  img {
    width: 14px;
    margin-left: 3px;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  span {
    line-height: 15px;
    font-size: 13px;
    text-decoration: underline;
  }
}
.my-table {
  margin-top: 15px;
}
.contrainer {
  display: flex;
  .contrainer-left {
    width: 260px;
    margin-right: 15px;
    min-height: calc(100vh - 90px);

    .card {
      height: 100%;
    }
  }
  .contrainer-right {
    flex: 1;
    min-width: 600px;
    min-height: calc(100vh - 90px);
  }
}

.el-tree {
  margin: -10px;
}
// ::v-deep .el-tree {
//   font-size: 14px;
//   .el-tree-node__content {
//     font-size: 14px;
//     height: 34px;
//     line-height: 34px;
//   }
//   .el-tree-node__children {
//     .el-tree-node .el-tree-node__content {
//       font-size: 14px;
//       margin: 4px 0;
//       height: 34px;
//       line-height: 34px;
//       padding-right: 18px;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       max-width: 350px;
//     }
//   }
// }
</style>
